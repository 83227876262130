.home-page {
    .text-zone {
        position: relative;
        left: 130px;
        width: 200px;
        display: inline-block;
        max-height: 90%;
        top: 50%;
        transform: translate(0, -50%);

        h1 {
            font-size: 60px;
            font-family: 'Coolvetica';
            color: #FF9966;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: -10px;
        }

        h2 {
            color: #EBEFBF;
            margin-top: 20px;
            font-weight: 400;
            font-size: 25px;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;
        }
    }
    .img-zone {
        position: absolute;
        display: inline-block;
        height: 100%;
        width: auto;
        margin-left: 150px;
        margin-right: 10%;
        top: 50%;
        transform: translate(0, -50%);

        img {
            height: 100%;
            width: auto;
            display: inline-block;
        }
    }
}

.flat-button {
    color: #FF9966;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #FF9966;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
        background: #FF9966;
        color: #333;
    }
}