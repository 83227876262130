.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.music-chart {
    position: absolute;
    margin-top: 150px;
    width: 100%;
    height: auto;
    opacity: 10%;
    float: left;

    img {
        max-width: 100%;
        max-height: auto;
        display: inline-block;
    }
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page, 
.contact-page,
.projects-page {
    .text-zone {
        position: absolute;
        left: 130px;
        top: 70px;
        width: 70%;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 60px;
            font-family: 'Coolvetica';
            color: #FF9966;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: -10px;
        }

        h2 {
            color: #EBEFBF;
            margin-top: 20px;
            font-weight: 400;
            font-size: 25px;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;
        }

        p {
            font-size: 13px;
            color: #fffcff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
            
            &:nth-of-type(4) {
                animation-delay: 1.4s;
            }

            &:nth-of-type(5) {
                animation-delay: 1.5s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fffcff;
        }
    }
}