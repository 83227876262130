.bouncing-logos {
    width: 40%;
    height: 100%;
    top: 0;
    padding-top: 10%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.bouncinglogos {
    font-size: 100px;
    height: 200px;

    .topRow {
        width: 100%;
        height: 100%;
        display: table;
        table-layout: fixed;
        border-collapse: separate;
        
        .topRowImg {
            display: table-cell;
            width: 33%;
        }

    }

    .bottomRow {
        width: 100%;
        height: 100%;
        display: table;
        table-layout: fixed;
        border-collapse: separate;
        
        .bottomRowImg {
            display: table-cell;
            width: 33%;
        }

    }

}

.iconContainer {
    display: flex;
    justify-content: center;
}

.icon11 {
    animation-name: growshrink11;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    transition-property: transform;
}
@keyframes growshrink11 {
    from,
    to {
        transform: scale(1);
    }
    4% {
        transform: scale(.6);
    }
    9% {
        transform: scale(1.3);
    }
    39% {
        transform: scale(1.3);
    }
    59% {
        transform: scale(1);
    }
}

.icon12 {
    animation-name: growshrink12;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    transition-property: transform;
}
@keyframes growshrink12 {
    from,
    to {
        transform: scale(1);
    }
    3% {
        transform: scale(1);
    }
    7% {
        transform: scale(.6);
    }
    12% {
        transform: scale(1.3);
    }
    42% {
        transform: scale(1.3);
    }
    62% {
        transform: scale(1);
    }
}
.icon13 {
    animation-name: growshrink13;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    transition-property: transform;
}
@keyframes growshrink13 {
    from,
    to {
        transform: scale(1);
    }
    6% {
        transform: scale(1);
    }
    10% {
        transform: scale(.6);
    }
    15% {
        transform: scale(1.3);
    }
    45% {
        transform: scale(1.3);
    }
    65% {
        transform: scale(1);
    }
}
.icon21 {
    animation-name: growshrink21;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    transition-property: transform;
}
@keyframes growshrink21 {
    from,
    from,
    to {
        transform: scale(1);
    }
    9% {
        transform: scale(1);
    }
    13% {
        transform: scale(.6);
    }
    18% {
        transform: scale(1.3);
    }
    48% {
        transform: scale(1.3);
    }
    68% {
        transform: scale(1);
    }
}
.icon22 {
    animation-name: growshrink22;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    transition-property: transform;
}
@keyframes growshrink22 {
    from,
    from,
    to {
        transform: scale(1);
    }
    12% {
        transform: scale(1);
    }
    16% {
        transform: scale(.6);
    }
    21% {
        transform: scale(1.3);
    }
    51% {
        transform: scale(1.3);
    }
    71% {
        transform: scale(1);
    }
}
.icon23 {
    animation-name: growshrink23;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    transition-property: transform;
}
@keyframes growshrink23 {
    from,
    to {
        transform: scale(1);
    }
    15% {
        transform: scale(1);
    }
    19% {
        transform: scale(.6);
    }
    24% {
        transform: scale(1.3);
    }
    54% {
        transform: scale(1.3);
    }
    74% {
        transform: scale(1);
    }
}